/*
PROSE
----------------------------------------------------------------*/

.prose {  
    * + :where(h1, h2, h3) {
        margin-block-start: calc(var(--spacing-prose) * 2);
    }

    :where(img, iframe, figure, svg) {
        margin-inline: auto;
    }

    p:has(:is(img, iframe, figure, svg)) {
        --spacing-prose: 1rlh;
        margin-block-start: var(--spacing-prose);
    }

    @media screen and (prefers-color-scheme: dark) {
        img {
            opacity: 0.9;
            box-shadow: rgba(0, 0, 0, 0.3) 0 0 6px;
        }
    }

    :where(ul, ol) {
        padding-left: 0.5rlh;
        margin-left: 0.5rlh;

        li {
            --spacing-prose: 0.25rlh;
        }
    }

    figcaption {
        padding: 1rem 0;
        text-align: center;
        font-family: var(--sans);
        font-size: 0.9em;
    }

    /* Break links and code */
    :where(code:not(pre > code), a) {
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }

    pre {
        font-size: var(--f6);
    }

    figure.image--full {
        margin: 2rlh -14vw;

        & img {
            display: block;
            margin: 0 auto;
        }

        @media screen and (max-width: 960px) {
            /* Edge-to-edge pixelfucking. */
            margin-left: auto;
            margin-right: auto;
        }
    }

    details summary + pre {
        margin-block-start: 0.5lh;
    }
}
