/*  From:
    https://github.com/mayank99/reset.css/blob/main/package/index.css
    https://piccalil.li/blog/a-more-modern-css-reset/
*/

*,
*::before,
*::after {
    box-sizing: border-box;
}

:where([hidden]:not([hidden='until-found'])) {
    display: none !important;
}

:where(html) {
    /* https://kilianvalkhof.com/2022/css-html/your-css-reset-needs-text-size-adjust-probably/ */
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    color-scheme: dark light;
    tab-size: 2;
}

@media (prefers-reduced-motion: no-preference) {
    :where(html:focus-within) {
        scroll-behavior: smooth;
    }
}

:where(body) {
    min-height: 100dvb;
    margin: 0;
}

:where(h1, h2, h3, h4, button, input, label) {
    line-height: 1.5;
}

:where(input, button, textarea, select) {
    font: inherit;
    color: inherit;
}

:where(textarea) {
    resize: vertical;
    resize: block;
}

:where(button, label, select, summary, [role='button'], [role='option']) {
    cursor: pointer;
}

:where(:disabled) {
    cursor: not-allowed;
}

:where(label:has(> input:disabled), label:has(+ input:disabled)) {
    cursor: not-allowed;
}

:where(a) {
    color: inherit;
    text-underline-offset: 0.2ex;
}

:where(ul) {
    list-style-type: disc;
}

:where(ul[role='list'], ol[role='list']) {
    list-style: none;
}

:where(img, svg, video, canvas, audio, iframe, embed, object) {
    display: block;
    vertical-align: middle;
}

:where(img, picture, svg, video) {
    max-inline-size: 100%;
    block-size: auto;
}

:where(p, h1, h2, h3, h4, h5, h6) {
    overflow-wrap: break-word;
}

:where(h1, h2, h3, h4) {
    text-wrap: balance;
}

:where(hr) {
    border: none;
    border-block-start: 1px solid;
    color: inherit;
    block-size: 0;
    overflow: visible;
}

:where(:focus-visible) {
    outline: 3px solid CanvasText;
    box-shadow: 0 0 0 5px Canvas;
    outline-offset: 1px;
}

:where(:focus-visible, :target) {
    scroll-margin-block: 8vh;
}

:where(.visually-hidden:not(:focus-within, :active)) {
    clip-path: inset(50%) !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden !important;
    position: absolute !important;
    white-space: nowrap !important;
    border: 0 !important;
}

:where(progress) {
    vertical-align: baseline;
}

:where(legend) {
    padding: 0;
}

::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

:-moz-focusring {
    outline: auto;
}

/**
Remove the inheritance of text transform in Edge and Firefox.
*/

:where(button, select) {
    text-transform: none;
}

:where(table) {
    border-collapse: collapse;
}

:where(body, h1, h2, h3, h4, p, figure, blockquote, dl, dd, ul, ol) {
    margin: 0;
}

:where(textarea:not([rows])) {
    min-height: 10em;
}
