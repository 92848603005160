/*
Qt Creator light color scheme
https://github.com/highlightjs/highlight.js/blob/main/src/styles/qtcreator-light.css
*/


.hljs {
  color: #000000;
  background: #ffffff;
}

.hljs-strong,
.hljs-emphasis {
  color: #000000;
}

.hljs-bullet,
.hljs-quote,
.hljs-number,
.hljs-regexp,
.hljs-literal {
  color: #000080;
}

.hljs-code
.hljs-selector-class {
  color: #800080;
}

.hljs-emphasis,
.hljs-stronge,
.hljs-type {
  font-style: italic;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-function,
.hljs-section,
.hljs-symbol,
.hljs-name {
  color: #808000;
}

.hljs-subst,
.hljs-tag,
.hljs-title {
  color: #000000;
}

.hljs-attribute {
  color: #800000;
}

.hljs-variable,
.hljs-params,
.hljs-title.class_,
.hljs-class .hljs-title {
  color: #0055AF;
}

.hljs-string,
.hljs-selector-id,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-type,
.hljs-built_in,
.hljs-template-tag,
.hljs-template-variable,
.hljs-addition,
.hljs-link {
  color: #008000;
}

.hljs-comment,
.hljs-meta,
.hljs-deletion {
  color: #008000;
}
