body {
    margin-inline: auto;
    padding-inline: 5%;
    padding-block: 0.5rlh;

    @media (min-width: 960px) {
        display: grid;
        grid-template-columns: 110px var(--content-width);
        column-gap: 3rlh;
        justify-content: center;
    }

    @media only screen and (min-width: 600px) {
        padding-block: 1rlh;
    }
}

.Page {
    display: grid;
    height: 100%;
    align-content: start;

    /* The thinking is to apply a margin *if needed*, so we use the fact that a bottom margin bleeds
       over when overflowing its parent. */
    @media (min-width: 960px) {
        grid-template-rows: minmax(3rlh, max-content) 1fr min-content;
        
        html:not(.hidden-title) & > header > :last-child {
          margin-block-end: 1rlh;
        }
    }
    
    @media (max-width: 959px) {
        > header > :last-child {
            margin-block-end: 1rlh;
        }
    }
    
    /* Ancient hack to prevent overflows. */
    .prose {
        min-width: 0;
    }

    footer {
        margin-block-start: 2rlh;

        nav {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;

            :first-child {
                justify-self: start;
            }

            :nth-child(2) {
                justify-self: center;
            }

            :nth-child(3) {
                justify-self: end;
            }
        }
    }
}

html.hidden-title :is(main > header, main > article > header) h1:not(:focus-within, :active) {
    clip-path: inset(50%) !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden !important;
    position: absolute !important;
    white-space: nowrap !important;
    border: 0 !important;
}
