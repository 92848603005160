/* Styles for Johan Brook.com

Built for IE6, made in Dreamweaver.

Just kidding.
--------------------------------------------- */

@layer setup, defaults, theme, utils;

@import 'css/reset.css' layer(setup);
@import 'css/variables.css' layer(setup);

@import 'css/defaults.css' layer(defaults);

@import 'css/layout.css' layer(theme);
@import 'css/theme.css' layer(theme);
@import 'css/highlightjs/qtcreator-light.css' layer(theme);
@import 'css/highlightjs/qtcreator-dark.css' layer(theme);

@import 'css/components.css';

@import 'css/changelog.css';
@import 'css/oblique-strategies.css';
@import 'css/recipe.css';
@import 'css/home.css';

@import 'css/typeset.css';

@import 'css/utils.css';
@import 'css/book.css';
