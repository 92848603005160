:is(.recipe-metadata) {
    font-weight: 650;
}

:is(.recipe-cookwares, .recipe-ingredients, .recipe-metadata, .recipe-instructions) h2 {
    display: none;
}

:is(.recipe-metadata, .recipe-ingredients, .recipe-cookwares) {
    :is(ul, ol) {
        list-style: none;
        padding-left: 0;
        margin-left: 0;
    }
}

.recipe-cookwares {
    h2 {
        font-size: var(--f4);
    }
}

:where(.recipe-ingredients, .recipe--ingredient, .recipe-cookwares) {
    font-weight: var(--fw-stronger);
    color: var(--color-text-high-contrast);
}

.recipe-instructions {
    border-top: 1px solid var(--border-color);
    margin-top: 1lh;
    padding-top: 1lh;

    ol li {
        margin-bottom: 0.5lh;
    }

    ol {
        padding-left: 0;
        margin-left: 0;
    }
}

.recipe--timer {
    font-weight: 650;
    text-decoration: underline;
    text-underline-offset: 0.6ex;
    text-decoration-style: wavy;
    text-decoration-color: var(--accent);
    white-space: nowrap;
}

.recipe--ingredient {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-underline-offset: 0.4ex;
    text-decoration-color: var(--color-faint);
}

.recipe--cookware {
    font-style: italic;
}
