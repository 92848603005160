@layer theme {
    .micro {
        /* List of notes */
        @media only screen and (max-width: 960px) {
            main > ol {
                list-style-position: inside;
            }
        }
        
        main > ol {
          --spacing: 3rlh;
          
          > * + * {
            padding-block-start: var(--spacing);
            border-top: 2px solid var(--border-color);
          }
        }
    }

    .Note {
        header h1 {
            margin-block-end: 0;
            font-size: 1rem;

            a {
                span {
                    color: var(--color-text-detail);
                    font-weight: 400;
                }

                &:hover span {
                    color: var(--link-accent-color);
                }
            }
        }

        .prose {
            h1 {
                font-size: var(--f3);
            }

            :where(h2, h3, h4, h5, h6) {
                font-size: var(--f4);
            }
        }
    }
}
