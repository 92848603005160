/*
POSTS LIST
----------------------------------------------------------------*/

:where(ol, ul):has(.PostItem) {
    display: grid;
    row-gap: 0.25rlh;
}

.PostItem {
    display: grid;
    grid-template-columns: 1fr 33%;
    column-gap: 0.5rlh;

    > * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    > :first-child {
        font-weight: 500;
    }

    a:not(:hover, :focus) {
        text-decoration: none;
    }

    time {
        font-size: var(--f5);
        font-variant-numeric: tabular-nums;
        letter-spacing: -0.008em;
        font-variation-settings: 'opsz' 20;

        &:not(.PostItem:hover &) {
            color: var(--color-text-detail);
        }
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 0;
    }
}
