.home {
    main {
        &.stack {
            --spacing: 2rlh;
        }

        article h1 {
            height: 3rlh;
            margin: 0;
        }

        section {
            > h1:first-of-type {
                margin-block-end: 1rlh;

                a {
                    &:not(:hover) {
                        text-decoration: none;
                    }
                }
                
                &::after {
                    content: '→' / '';
                    display: inline;
                    color: var(--color-faint);
                    margin-inline-start: 0.25em;
                    font-size: var(--f4);
                }
                
                &:hover::after {
                    color: var(--link-accent-color);
                }
            }

            > header {
                margin-block-end: 1rlh;

                h1 {
                    font-size: var(--f2);
                    margin-bottom: 0;
                }
            }
        }
    }
}
