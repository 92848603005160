nav {
  a {
    color: var(--color-text);
    white-space: nowrap;
    text-decoration: none;
    
    &:is(:hover, :focus-visible):not(:is([aria-current='page'] > *)) {
        color: var(--link-color-hover);
        text-decoration: underline;
    }
  }

  :is([aria-current='page'] a, [aria-current='page']) {
    color: var(--color-text-high-contrast);
  }
}

.NavList {
    li:where([aria-current='page']) {
        position: relative;

        &::after {
            content: '⇠';
            display: inline;
            margin-left: 0.25em;
            white-space: nowrap;
            color: var(--neon);
        }
    }

    a {
        position: revert;
    }
}

.MainNav {
    position: relative;
    z-index: 2;

    & .sticky {
        position: sticky;
        top: 1rlh;
    }

    h1 {
        height: 2rlh;
        display: grid;
        align-content: end;
        margin-block-end: 1rlh;
        line-height: 1rlh;
        font-weight: 480;

        .BackLink {
            position: absolute; /* Don't take up vertical space */
        }
    }
}

.BackLink {
    text-decoration: none;
    color: var(--color-text);
    background-color: var(--color-bg-secondary);
    width: 1.6em;
    text-align: center;
    line-height: 1.6em;
    aspect-ratio: 1/1;
    display: inline-block;
    border-radius: 4em;
    opacity: 0.8;

    a:is(:hover, :focus) > & {
        opacity: 1;
    }
}

.MobileNav {
    display: none;
    margin-block-end: 1rlh;

    .MobileNav__Home {
        font-weight: 500;
    }

    .NavList {
        min-width: 9rem;

        li:where([aria-current='page'])::after {
            display: none;
        }
        
        a {
            display: block;
        }
    }

    .NavList {
        box-shadow: color-mix(in oklab, var(--color-bg) 50%, black 20%) -10px 10px 30px;
        background-color: Canvas;
        padding: 5px;
        margin-right: -5px;
        margin-top: 0.25lh;
        border-radius: 8px;
        font-size: 1.1rem;
        line-height: 2;

        li {
            border-radius: 6px;
            padding: 0 10px;

            &[aria-current='page'] {
                background-color: var(--color-bg-secondary);
            }
        }
    }
    
    .MobileNav__Trigger {
        border-radius: 4px;
        padding: 0 5px;
        margin-inline-end: -5px;
        display: inline-block;
    }
    
    /* "Implementation specific" */
    
    details {
        margin: 0;
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;
    }

    summary {
        text-align: end;
        white-space: nowrap;
        user-select: none;
        font-weight: 500;

        &::-webkit-details-marker {
            display: none;
        }
    }

    details[open] summary .MobileNav__Trigger {
        background-color: var(--color-bg-secondary);
        color: var(--blue);
    }
}

@media only screen and (max-width: 959px) {
    .MainNav {
        display: none;
    }
    .MobileNav {
        display: flex;
        justify-content: space-between;
    }
}
