@import 'css/navigation.css';
@import 'css/posts-list.css';
@import 'css/prose.css';
@import 'css/notes.css';

.TagCloud {
    li {
        display: inline;
    }
    
    a {
        font-size: 1em;
    }
}
