/* =CHANGELOG
--------------------------------------------- */

.changelog {
    & .prose {
        & > ul {
            padding-left: 0;
            margin-bottom: 1rlh;

            & ul {
                margin-bottom: 0;
            }
        }
    }
}
