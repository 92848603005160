:root {
    /* Colors */

    --mood: oklch(68% 0.155 47.65);
    --accent: hotpink;

    --light: color-mix(in oklab, var(--mood) 8%, oklch(84% 0 0));
    --dark: color-mix(in oklab, var(--mood) 4%, oklch(16% 0 0));

    --neon: oklch(77.6% 0.185 74.12);
    --yellow: oklch(82.94% 0.185 91.34);
    --red: oklch(72% 0.185 28.59);
    --green: oklch(77.94% 0.217 141.54);
    --blue: oklch(58.31% 0.217 253.71);

    --grey: hsl(214.51 7.24% 47.56%);
    --grey-light: hsl(173 7% 36% / 0.5);
    --grey-lightest: hsl(173 7% 93%);

    /* Background vars, ordered from high contrast -> subtle */
    --color-bg: #fff;
    --color-bg-secondary: hsl(240 59.76% 96.52%);

    /* Foreground vars, order from high contrast -> subtle */
    --color-text: hsl(0 0% 16%);
    --color-text-high-contrast: black;
    --color-text-detail: color-mix(in oklab, var(--mood) 10%, black 50%);
    --color-faint: color-mix(in oklab, var(--mood) 10%, black 20%);

    --link-color: var(--color-text-high-contrast);
    --link-color-hover: var(--color-text-high-contrast);
    --link-color-visited: hsl(266 100% 44%);
    --link-accent-color: var(--neon);

    --border-color: color-mix(in oklab, var(--mood) 3%, black 15%);

    --btn-color: var(--color-text);
    --btn-bg: var(--color-bg-secondary);
    --btn-bg-hover: hsl(240 59.76% 95.52%);

    --pre-background: var(--color-bg-secondary);
    --code-background: rgb(0 0 0 / 5%);
    --code-color: var(--color-text-high-contrast);

    @media screen and (prefers-color-scheme: dark) {
        --yellow: oklch(90.29% 0.2146 98.81);
        --red: oklch(74.93% 0.1905 28.59);
        --green: oklch(88.24% 0.271 141.54);
        --blue: oklch(67.35% 0.18 253.71);
        --grey: hsl(214.51, 6.64%, 61.44%);

        --color-bg: var(--dark);
        --color-bg-secondary: color-mix(in oklab, var(--mood) 1%, white 4%);
        --color-text: var(--light);
        --color-text-high-contrast: color-mix(in oklab, var(--mood) 6%, oklch(95% 0 0));
        --color-text-detail: color-mix(in oklab, var(--mood) 10%, white 50%);
        --color-faint: color-mix(in oklab, var(--mood) 10%, white 20%);

        --border-color: color-mix(in oklab, var(--mood) 3%, white 8%);

        --link-color-visited: #7a8be5;
        --code-color: rgb(236 216 142);
        --code-background: rgb(255 255 255 / 2%);

        --btn-bg-hover: color-mix(in oklab, var(--mood) 5%, white 8%);
    }

    /* Typography */

    --sans: system-ui, sans-serif;
    --mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    --serif: Charter, 'Bitstream Charter', 'Sitka Text', Cambria, serif;

    --leading: 1.618;

    --fw-stronger: 450;

    --tracking: -0.014em;
    --tracking-f0: 0;
    --tracking-f1: -0.021em;
    --tracking-f2: -0.02em;
    --tracking-f3: -0.018em;
    --tracking-f4: 0;
    --tracking-f5: 0;
    --tracking-f6: 0;

    /* @link @link https://utopia.fyi/clamp/calculator?a=320,653,32—60 */
    @utopia clamps({
        minWidth: 320,
        maxWidth: 653,
        pairs: [
          [32, 48]
        ],
        prefix: "step-title",
      });

    @utopia typeScale({
        minWidth: 320,
        maxWidth: 653,
        minFontSize: 16,
        maxFontSize: 18,
        minTypeScale: 1.125,
        maxTypeScale: 1.2,
        positiveSteps: 3,
        negativeSteps: 3,
        prefix: "step",
    });

    --f0: var(--step-title-32-48);
    --f1: var(--step-3);
    --f2: var(--step-2);
    --f3: var(--step-1);
    --f4: var(--step-0);
    --f5: var(--step--1);
    --f6: var(--step--2);
    --f7: var(--step--3);

    /* Measures */

    --inset: 0.5rlh;

    --measure: 62ch;
    --measure-narrow: 45ch;
    --measure-wide: 100ch;

    --content-width: var(--measure);

    --spacing-base: 1rlh;
    --spacing-prose: 0.75rlh;

    --spacing-none: 0;
    --spacing-extra-small: calc(var(--spacing-base) / 5);
    --spacing-small: calc(var(--spacing-base) / 2);
    --spacing-medium: var(--spacing-base);
    --spacing-large: calc(var(--spacing-base) * 2);
    --spacing-extra-large: calc(var(--spacing-base) * 3);
    --spacing-extra-extra-large: calc(var(--spacing-base) * 4);
    --spacing-extra-extra-extra-large: calc(var(--spacing-base) * 6);
}
